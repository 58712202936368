import styled from "styled-components"

export const Title = styled.h1`
  font-size: 39px;
  font-family: "agenda";
  color: ${({ theme }) => theme.colors.navyPrimary};
  text-align: center;
  margin-right: 24px;
`

export { default as Blog } from "./Blog"
