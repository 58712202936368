import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { uniq, take, orderBy } from "lodash";
import styled from "styled-components";
import { Container } from "../../components/layouts/components/Container";
import Layout from "../../components/layouts";
import { Title, Blog } from "../../components/blog";
import ButtonLargePrimaryComponent from "../../components/layouts/components/ButtonLargePrimaryComponent";
import FormPanelDesktopComponent from "../../components/layouts/FormPanelDesktopComponent";
import { canonicalUrlResolver } from "../../utils/routeHelper"

export const query = graphql`
  {
    prismic {
      allBlogs {
        edges {
          node {
            title
            author_name
            published_date
            featured_image
            is_featured_blog
            subtitle
            _linkType
            _meta {
              tags
              uid
            }
          }
        }
      }
    }
  }
`;

const PAGINATION_MAX = 8;
const ALL_TAG = "All";
const MAX_LIST_TAG_LENGTH = 4;

const handleDestructure = ({ prismic }) => {
  const blogs = prismic.allBlogs.edges.map((x) => {
    const { _meta, ...rest } = x.node;

    return {
      ...rest,
      slug: _meta.uid,
      tags: _meta.tags,
    };
  });

  const allTags = blogs.flatMap((x) => x.tags);
  const tags = uniq(allTags);

  return {
    tags,
    blogPosts: blogs,
  };
};

const TagDropdown = ({ selectedTag, handleTagDropdownChange, allTags }) => (
  <TagDropDown
    className="form-select"
    value={selectedTag}
    onChange={handleTagDropdownChange}
  >
    {allTags.map((x) => (
      <option key={x} value={x}>
        {x === ALL_TAG ? "All Categories" : x}
      </option>
    ))}
  </TagDropDown>
);

const Blogs = ({ data }) => {
  const [blogs, setBlogs] = useState([]);
  const [featuredBlog, setFeaturedBlog] = useState(undefined);
  const [totalBlogs, setTotalBlogs] = useState(0);
  const [totalDisplayedBlogs, setTotalDisplayedBlogs] = useState(
    PAGINATION_MAX
  );
  const [selectedTag, setSelectedTag] = useState(ALL_TAG);
  const [allTags, setAllTags] = useState([]);

  useEffect(() => {
    const { blogPosts, tags } = handleDestructure(data);

    const b = [...blogPosts];

    const filteredBlogs =
      selectedTag === ALL_TAG
        ? b
        : b.filter((x) => x.tags.includes(selectedTag));

    const featuredBlog =
      selectedTag === ALL_TAG
        ? filteredBlogs.find((x) => x.is_featured_blog)
        : null;

    const otherBlogs = filteredBlogs.filter(
      (x) => selectedTag !== ALL_TAG || !x.is_featured_blog
    );

    setTotalBlogs(filteredBlogs.length);
    setAllTags([ALL_TAG, ...tags]);

    setFeaturedBlog(featuredBlog);
    setBlogs(orderBy(otherBlogs, "published_date", "desc"));
  }, [selectedTag, data]);

  const handleTagChange = (tag) => () => {
    setSelectedTag(tag);
    setTotalDisplayedBlogs(PAGINATION_MAX);
  };

  const handleTagDropdownChange = (event) => {
    setSelectedTag(event.target.value);
  };

  const showMore = () => {
    setTotalDisplayedBlogs(totalDisplayedBlogs + PAGINATION_MAX);
  };

  const url = canonicalUrlResolver('/blogs');

  return (
    <Layout title="The deBa blogs" description="The deBa blogs index page" canonicalUrl={url}>
      <ContentContainer>
        <BlogsHolder>
          <HeaderDesktop
            className={
              totalBlogs > MAX_LIST_TAG_LENGTH
                ? "d-lg-flex align-items-center justify-content-between"
                : ""
            }
          >
            <Title>The deBa blog</Title>
            {totalBlogs <= MAX_LIST_TAG_LENGTH ? (
              <TagsHolder>
                {allTags.map((t, index) => (
                  <Tags
                    onClick={handleTagChange(t)}
                    isLast={index === allTags.length - 1}
                    active={selectedTag === t}
                  >
                    {t}
                  </Tags>
                ))}
              </TagsHolder>
            ) : (
              <div>
                <TagDropdown
                  allTags={allTags}
                  handleTagDropdownChange={handleTagDropdownChange}
                  selectedTag={selectedTag}
                />
              </div>
            )}
          </HeaderDesktop>
          <HeaderMobile>
            <Title>Blogs</Title>
            <div>
              <TagDropdown
                allTags={allTags}
                handleTagDropdownChange={handleTagDropdownChange}
                selectedTag={selectedTag}
              />
            </div>
          </HeaderMobile>
          {featuredBlog ? (
            <div>
              <FeaturedBlogHolder>
                <Blog {...featuredBlog} isFeatured={true} />
              </FeaturedBlogHolder>
              <FeaturedBlogHolderMobile>
                <div>
                  <Blog {...featuredBlog} />
                </div>
              </FeaturedBlogHolderMobile>
            </div>
          ) : (
            <></>
          )}
          <div className="row">
            {take(blogs, totalDisplayedBlogs).map((x) => (
              <BlogHolder className="col-12 col-md-6">
                <Blog {...x} />
              </BlogHolder>
            ))}
          </div>
        </BlogsHolder>

        {totalDisplayedBlogs < blogs.length ? (
          <ShowMore onClick={showMore}>Show More</ShowMore>
        ) : (
          <></>
        )}
      </ContentContainer>
      <FooterWrapper>
        <FormPanelDesktopComponent />
      </FooterWrapper>
    </Layout>
  );
};

const FooterWrapper = styled.div`
  padding-bottom: 48px;
`;

const ContentContainer = styled(Container)`
  margin-top: 48px;
  padding: 0 24px 24px;

  @media (min-width: 992px) {
    padding: 0 0 48px;
  }
`;

const TagsHolder = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
`;

const Tags = styled.button`
  outline: none;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.navyPrimary};
  font-family: Helvetica;
  font-size: 20px;

  ${({ active }) =>
    active &&
    `border-bottom: 4px solid #235C65; 
    font-weight: bold;
  `};
  ${({ isLast }) => !isLast && "margin-right: 16px"};
`;

const BlogsHolder = styled.div`
  @media (min-width: 992px) {
    padding: 0 200px;
  }
`;

const BlogHolder = styled.div`
  margin-bottom: 24px;
`;
const FeaturedBlogHolder = styled(BlogHolder)`
  display: none;
  margin: 0 24px 24px;

  width: 100% !important;

  @media (min-width: 992px) {
    padding: 0;
    margin: 0 0 48px;
    display: block;
  }
`;

const FeaturedBlogHolderMobile = styled(FeaturedBlogHolder)`
  display: block;
  margin: 0 auto 24px;

  @media (min-width: 992px) {
    display: none;
  }
`;

const HeaderDesktop = styled.div`
  display: none;

  @media (min-width: 992px) {
    display: block;
  }

  margin-bottom: 60px;
`;

const HeaderMobile = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: center;

  justify-content: space-between;

  @media (min-width: 992px) {
    display: none;
  }

  ${Title} {
    font-size: 32px;
    text-align: left;
  }
`;

const TagDropDown = styled.select`
  color: ${({ theme }) => theme.colors.navyPrimary};
  font-family: Helvetica;
  background-size: 24px;
  padding 14px 56px 10px 16px !important;
`;

const ShowMore = styled(ButtonLargePrimaryComponent)`
  margin: 0 auto;
`;

export default Blogs;
