import styled from "styled-components";

export const Container = styled.div`
  max-width: 1200px;
  margin: 119px auto 0;

  @media only screen and (max-width: 800px) {
    margin: 50px auto 0;
  }
`;
